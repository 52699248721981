import {useReactiveVar} from "@apollo/client";
import {breadcrumbTrail} from "../../apollo/reactiveVars";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {
  getSideMenuOpenKey,
  getSideMenuSelectedKeys
} from "../../utils/functions";

function useLocalNavigation() {
  const trail = useReactiveVar(breadcrumbTrail)
  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumb: "Accueil"
    },
    {
      path: "/contacts/customers",
      breadcrumb: "Clients",
    },
    {
      path: "/contacts/customers/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/contacts/suppliers",
      breadcrumb: "Fournisseurs"
    },
    {
      path: "/contacts/suppliers/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/contacts/customers/:_id",
      breadcrumb: "Client"
    },
    {
      path: "/services/services",
      breadcrumb: "Préstations"
    },
    {
      path: "/services/services/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/services/projects",
      breadcrumb: "Projets"
    },
    {
      path: "/services/projects/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/accounting/proformas",
      breadcrumb: "Proformas"
    },
    {
      path: "/accounting/proformas/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/accounting/invoices",
      breadcrumb: "Factures"
    },
    {
      path: "/accounting/invoices/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/accounting/orders",
      breadcrumb: "Commandes"
    },
    {
      path: "/accounting/orders/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/accounting/purchases",
      breadcrumb: "Achats"
    },
    {
      path: "/accounting/purchases/:_id",
      breadcrumb: trail ?? ""
    },
    {
      path: "/accounting/payments",
      breadcrumb: "Paiements"
    },
    {
      path: "/hr/employees",
      breadcrumb: "Employés"
    },
    {
      path: "/hr/employees/:_id",
      breadcrumb: trail ?? ""
    },
  ]
  
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, {excludePaths: ["/contacts", "/accounting", "/services", "/hr"]})
  
  return {
    breadcrumbs,
    initialOpenKeys:getSideMenuOpenKey(breadcrumbs[breadcrumbs.length - 1].key ?? ""),
    selectedKeys:getSideMenuSelectedKeys(breadcrumbs[breadcrumbs.length - 1].key)
  }
}

export {useLocalNavigation}