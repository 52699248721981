import React from "react"
import {RealmAppProvider, useRealmApp} from "./RealmApp";
import Login from "./layout/login";
import RealmApolloProvider from "./apollo/RealmApolloProvider";
import Layout from "./layout";
import {BrowserRouter as Router} from "react-router-dom";
import {
  faAddressBook,
  faArrowRightFromBracket,
  faCheck,
  faCheckSquare,
  faCoffee,
  faFileInvoiceDollar,
  faFloppyDisk,
  faHome,
  faInfo,
  faPaperPlane,
  faPenToSquare,
  faPerson,
  faPlus,
  faPrint,
  faShop,
  faTrash,
  faUserPlus,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import {faWindowClose} from '@fortawesome/free-regular-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'

library.add(
  faInfo,
  faPerson,
  faFileInvoiceDollar,
  faAddressBook,
  faCheckSquare,
  faCoffee,
  faHome,
  faTrash,
  faUserPlus,
  faCheck,
  faWindowClose,
  faXmark,
  faShop,
  faFloppyDisk,
  faPlus,
  faPenToSquare,
  faPrint,
  faPaperPlane,
  faArrowRightFromBracket
)

const RequireLoggedInUser = ({children}) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <Login/>;
};

function App() {
  return (
    <RealmAppProvider appId={process.env.REACT_APP_REALM_ID}>
      <Router>
        <RequireLoggedInUser>
          <RealmApolloProvider>
            <Layout/>
          </RealmApolloProvider>
        </RequireLoggedInUser>
      </Router>
    </RealmAppProvider>
  );
}

export default App;
