import {useRealmApp} from "../RealmApp";
import {useNavigate} from "react-router-dom";
import * as Realm from "realm-web";
import {message} from "antd";

export default function useLogin() {
  const app = useRealmApp();
  const navigate = useNavigate()
  
  async function handleLogin(email, password) {
    
    try {
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      message.success("Bienvenu "
        + app.currentUser.customData?.firstName + " "
        + app.currentUser.customData?.lastName, 2)
      navigate("/")
    } catch (err) {
      console.log(err)
    }
  };
  
  function handleLogout() {
    app.logOut()
  }
  
  return {
    handleLogin,
    handleLogout
  }
}