import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const sideMenuRoutes = [
  {
    label: "Accueil",
    href: "/",
    type: "single",
    icon: <FontAwesomeIcon icon="house"/>,
  },
  {
    label: "Services",
    type: "multiple",
    icon: <FontAwesomeIcon icon="shop"/>,
    subItems: [
      {
        label: "Préstations",
        href: "/services/services"
      },
      {
        label: "Projets",
        href: "/services/projects"
      },
    ]
  },
  {
    label: "Contacts",
    type: "multiple",
    icon: <FontAwesomeIcon icon="address-book"/>,
    subItems: [
      {
        label: "Clients",
        href: "/contacts/customers"
      },
      {
        label: "Fournisseurs",
        href: "/contacts/suppliers"
      }
    ]
  },
  {
    label: "Comptabilité",
    type: "multiple",
    icon: <FontAwesomeIcon icon="file-invoice-dollar"/>,
    subItems: [
      {
        label: "Proformas",
        href: "/accounting/proformas"
      },
      {
        label: "Factures",
        href: "/accounting/invoices"
      },
      {
        label: "Commandes",
        href: "/accounting/orders"
      },
      {
        label: "Achats",
        href: "/accounting/purchases"
      },
      {
        label: "Paiements",
        href: "/accounting/payments"
      },
    ]
  },
  {
    label: "Ressources Humaine",
    type: "multiple",
    icon: <FontAwesomeIcon icon="person"/>,
    subItems: [
      {
        label: "Employés",
        href: "/hr/employees"
      },
    ]
  },
]


export {sideMenuRoutes}