function getTTC(price, qty, tva) {
  let ttc = price * qty
  if (tva === 0) {
    return {
      ttc: Math.round(ttc * 100) / 100,
      ht: Math.round(ttc * 100) / 100,
      tva: 0
    }
  } else {
    return {
      ht: Math.round(ttc * 100) / 100,
      ttc: Math.round((ttc * (tva / 100) + ttc) * 100) / 100,
      tva: Math.round((ttc * (tva / 100)) * 100) / 100
    }
  }
}

function getProjectState(state) {
  switch (state) {
    case 0:
      return "en cours"
    case 1:
      return "terminé"
    case 2:
      return "facturé"
    default:
      return "annulé"
  }
}

function getSideMenuOpenKey(location) {
  const keys = []
  if (location.includes("services")) {
    keys.push("Services")
  }
  if (location.includes("contacts")) {
    keys.push("Contacts")
  }
  if (location.includes("accounting")) {
    keys.push("Comptabilité")
  }
  if (location.includes("hr")) {
    keys.push("Ressources Humaine")
  }
  return keys
}
function getSideMenuSelectedKeys(location) {
  const keys = []
  if (location==="/"){
    keys.push("/")
  }
  if (location.includes("/services/services")) {
    keys.push("/services/services")
  }
  if (location.includes("/projects")) {
    keys.push("/services/projects")
  }
  if (location.includes("/customers")) {
    keys.push("/contacts/customers")
  }
  if (location.includes("/suppliers")) {
    keys.push("/contacts/suppliers")
  }
  if (location.includes("/proformas")) {
    keys.push("/accounting/proformas")
  }
  if (location.includes("/invoices")) {
    keys.push("/accounting/invoices")
  }
  if (location.includes("/orders")) {
    keys.push("/accounting/orders")
  }
  if (location.includes("/purchases")) {
    keys.push("/accounting/purchases")
  }
  if (location.includes("/employees")) {
    keys.push("/hr/employees")
  }
  return keys
}

export {getTTC, getProjectState, getSideMenuOpenKey,getSideMenuSelectedKeys}