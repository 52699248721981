import React from "react"
import DesktopLayout from "./desktop";
import AppRoutes from "../components/appRoutes";

export default function Layout() {
  
  return (
    <DesktopLayout>
      <AppRoutes/>
    </DesktopLayout>
  )
}