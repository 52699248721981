import React, {lazy, Suspense} from 'react';
import {Route, Routes} from "react-router-dom";
import {Skeleton} from "antd";

const Customers = lazy(() => import("./contacts/customer/customers"));
const Customer = lazy(() => import("./contacts/customer/customer"));
const Suppliers = lazy(() => import("./contacts/suppliers/suppliers"))
const Supplier = lazy(() => import("./contacts/suppliers/supplier"));
const Services = lazy(() => import("./services/services"));
const Service = lazy(() => import("./services/service"));
const Proformas = lazy(() => import("./accounting/bills/proformas/proformas"));
const Purchases = lazy(() => import("./accounting/bills/purchases/purchases"));
const Purchase = lazy(() => import("./accounting/bills/purchases/purchase"));
const Invoices = lazy(() => import("./accounting/bills/invoices/invoices"));
const Payments = lazy(() => import("./accounting/payments/payments"));
const Proforma = lazy(() => import("./accounting/bills/proformas/proforma"));
const Invoice = lazy(() => import("./accounting/bills/invoices/invoice"));
const Employees = lazy(() => import("./hr/employees"))
const Employee = lazy(() => import("./hr/employee"))
const Orders = lazy(() => import("./accounting/orders/orders"))
const Order = lazy(() => import("./accounting/orders/order"))

export default function AppRoutes() {
  return (
    <Suspense fallback={<Skeleton active/>}>
      <Routes>
        <Route path="/" index element={"hello"}/>
        <Route path="contacts">
          <Route path="customers">
            <Route index element={<Customers/>}/>
            <Route path=":_id" element={<Customer/>}/>
          </Route>
          <Route path="suppliers">
            <Route index element={<Suppliers/>}/>
            <Route path=":_id" element={<Supplier/>}/>
          </Route>
        </Route>
        <Route path="services">
          <Route path="services">
            <Route index element={<Services/>}/>
            <Route path=":_id" element={<Service/>}/>
          </Route>
        </Route>
        <Route path="accounting">
          <Route path="proformas">
            <Route index element={<Proformas/>}/>
            <Route path=":_id" element={<Proforma/>}/>
          </Route>
          <Route path="invoices">
            <Route index element={<Invoices/>}/>
            <Route path=":_id" element={<Invoice/>}/>
          </Route>
          <Route path="orders">
            <Route index element={<Orders/>}/>
            <Route path=":_id" element={<Order/>}/>
          </Route>
          <Route path="purchases">
            <Route index element={<Purchases/>}/>
            <Route path=":_id" element={<Purchase/>}/>
          </Route>
          <Route path="payments">
            <Route index element={<Payments/>}/>
          </Route>
        </Route>
        <Route path="hr">
          <Route path="employees">
            <Route index element={<Employees/>}/>
            <Route path=":_id" element={<Employee/>}/>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}