import React, {useState} from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography
} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";

import {sideMenuRoutes} from "../utils/sideMenuRoutes";
import {useRealmApp} from "../RealmApp";
import useLogin from "../hooks/login";
import Logo from "../assets/logoWhite.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocalNavigation} from "../hooks/navigation/navigation";

const {Header, Content, Sider} = Layout;
const {Text} = Typography;

export default function DesktopLayout(props) {
  const navigate = useNavigate();
  const {breadcrumbs, initialOpenKeys,selectedKeys} = useLocalNavigation()
  const [openKeys, setOpenKeys] = useState([...initialOpenKeys]);
  const app = useRealmApp();
  const {handleLogout} = useLogin()
  
  function onMenuClick(params) {
    navigate(params.key);
  }
  
  return (
    <Layout style={{height: "100%", overflow: "auto"}}>
      <Header
        style={{
          zIndex: 2,
          boxShadow: "0px 3px 3px rgba(0,0,0,0.08)",
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Link to="/">
              <img src={Logo} alt="ubiquity-logo" style={{height: 38}}/>
            </Link>
          </Col>
          <Col>
            <Space>
              <Avatar icon={<UserOutlined/>}/>
              <Text level={5} style={{color: "white"}} strong>
                {app?.currentUser.customData.firstName + " " + app?.currentUser.customData.lastName}
              </Text>
              <Tooltip
                title="Déconnecter"
              >
                <Button
                  onClick={() => handleLogout()}
                  style={{backgroundColor: "transparent"}}
                  type="link"
                  icon={<FontAwesomeIcon icon="arrow-right-from-bracket"
                                         color="white"/>}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout style={{height: "100vh"}} hasSider>
        <Sider
          width={230}
          style={{
            overflow: "auto",
            left: 0,
            height: "100%"
          }}
        >
          <Menu
            mode="inline"
            onClick={onMenuClick}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={(keys) => setOpenKeys(keys)}
            style={{
              maxWidth: 230,
              height: "100%"
            }}
          >
            {sideMenuRoutes.map((item) =>
              item.type === "single" ? (
                <Menu.Item key={item.href} icon={item.icon}>
                  {item.label}
                </Menu.Item>
              ) : (
                <Menu.SubMenu
                  key={item.label}
                  icon={item.icon}
                  title={item.label}
                >
                  {item.subItems.map((i) =>
                    (
                      <Menu.Item key={i.href}>
                        {i.label}
                      </Menu.Item>
                    )
                  )}
                </Menu.SubMenu>
              )
            )}
          </Menu>
        </Sider>
        <Layout style={{width: "100%"}}>
          <Content style={{padding: 10,}}>
            <div style={{marginBottom: 10}}>
              <Breadcrumb>
                {breadcrumbs.map(breadcrumb => (
                  <Breadcrumb.Item key={breadcrumb.key}>
                    <Link to={breadcrumb.key}>
                      {breadcrumb.breadcrumb}
                    </Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
            <div style={{backgroundColor: "white", borderRadius: "5px",}}>
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}